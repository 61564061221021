import React from 'react'
import PrivacyPolicy from '../components/common/PrivacyPolicy/PrivacyPolicy'
import Layout from '../components/en/Layout/Layout'

const Privacy = () => (
  <Layout
    title='Privacy Policy | the.good.code; - Your Guide to How We Protect Your Data'
    description='Our privacy policy page explains how we collect, use, and protect your personal information. Learn about your data privacy rights and how we keep your information secure.'
  >
    <PrivacyPolicy />
  </Layout>
)

export default Privacy
